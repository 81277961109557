import React from 'react'
import PropTypes from 'prop-types'
import { Nav } from '../components/nav/Nav'

import '../scss/all.scss'

const Layout = ({ children }) => (
  <>
    <div className="wrapper">
      <Nav />
      <main>{children}</main>
    </div>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
