import React from 'react'

const links = ['Over ons', 'Werkwijze']
export const Nav = () => (
  <nav className="navigation" data-module="nav/Nav">
    <ul className="navigation__items">
      <li className="navigation__item logo logo--small">
        <a href="/" className="visually-hidden">
          Homepage
        </a>
      </li>
      {links.map(link => (
        <li className="navigation__item" key={link.replace(' ', '')}>
          <a
            className="navigation__link"
            href={`/${link.replace(' ', '-').toLowerCase()}`}
          >
            {link}
          </a>
        </li>
      ))}
      <li className="navigation__item navigation__item--right">
        <div className="button button--call-to-action button--passive">
          tel: +316 17 40 96 63
        </div>
      </li>
    </ul>
  </nav>
)
